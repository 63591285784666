<template>
	<div class="page">
		<div class="title">
			<div class="titleL">会员余额</div>
			<div class="titleR">
				<el-button type="infor" @click="$router.back()">返回</el-button>
			</div>
		</div>
		<div class="tit">
			<div class="titL">
				<span class="titText">余额信息</span>
			</div>
			<div class="titR">
			</div>
		</div>
		<!-- 会员资料 -->
		<div class="msgBox">
			<el-row>
				<el-col class="msgItem" :span="4">
					卡号：{{userDetail.cardInfo.cardId}}
				</el-col>
				<el-col class="msgItem" :span="4">
					累计充值：{{userDetail.rechargeSum}}
				</el-col>
				<el-col class="msgItem" :span="4">
					累计消费：{{userDetail.consumeSum}}
				</el-col>
				<el-col class="msgItem" :span="4">
					余额：{{userDetail.cardInfo.userYue}}
					<img class="editImg" v-if="$buttonAuthority('huiyuan-huiyuanList-see-editBalance')" @click="dialogEditYue_state=true" src="../../../assets/img/shouyin/edit.png"/>
				</el-col>
				<el-col class="msgItem" :span="4">
					成长值：{{userDetail.vipGrades.score}}
					<img class="editImg" v-if="$buttonAuthority('huiyuan-huiyuanList-see-editGrowth')" @click="dialogEditGrowUp_state=true" src="../../../assets/img/shouyin/edit.png"/>
				</el-col>
			</el-row>
		</div>
		<el-radio-group v-model="tabIndex" @change="changeTabIndex" style="padding:20px 20px 0 20px;">
			<el-radio-button :label="0">资产记录</el-radio-button>
			<el-radio-button :label="1">成长值记录</el-radio-button>
		</el-radio-group>
		<div v-if="tabIndex===0" class="tabContent">
			<!-- 搜索栏 -->
			<div class="tabView">
				<div class="tabViewB">
					<div class="search">
						<div class="searchTitle">类型</div>
						<el-select v-model="consumeType" @change="changeConsumeType" placeholder="请选择" style="width: 143px;">
							<el-option v-for="(item,index) in [
								{label:'全部',value:0},
								{label:'充值',value:1},
								{label:'消费',value:2},
								{label:'退货',value:3},
								{label:'修改',value:5},
							]" :key="index" :label="item.label" :value="item.value">
							</el-option>
						</el-select>
					</div>
					<div class="search">
						<div class="searchTitle">日期</div>
						<el-date-picker
							v-model="timeRange"
							@change="changeTimeRange"
							type="daterange"
							start-placeholder="开始时间"
							end-placeholder="结束时间"
						>
						</el-date-picker>
					</div>
					<div class="tabViewBR">
						<!-- <el-button>导出</el-button>
						<el-button type="primary" >新建商品</el-button> -->
					</div>
				</div>
			</div>
			<!-- 表格 -->
			<div class="tableBox">
				<el-table :data="yueRecordList" style="width: 100%" height="100%">
					<el-table-column prop="date" label="日期">
						<template slot-scope="scope">
							{{scope.row.operationInfo.create.handleTime}}
						</template>
					</el-table-column>
					<el-table-column prop="name" label="关联单号">
						<template slot-scope="scope">
							<span class="linkColor" @click="toOrderDetail(scope.row)">{{scope.row.orderNum}}</span>
						</template>
					</el-table-column>
					<el-table-column prop="province" label="类型">
						<template slot-scope="scope">
							<span v-if="scope.row.consumeType===1">充值</span>
							<span v-if="scope.row.consumeType===2">消费</span>
							<span v-if="scope.row.consumeType===3">退货</span>
							<span v-if="scope.row.consumeType===4">换货</span>
							<span v-if="scope.row.consumeType===5">修改</span>
							<span v-if="scope.row.consumeType===6">退款</span>
						</template>
					</el-table-column>
					<el-table-column prop="city" label="变化值">
						<template slot-scope="scope">
							<span v-if="scope.row.consumeType===1||scope.row.consumeType===3||scope.row.consumeType===5" style="color:rgb(24, 164, 76)">+{{scope.row.consume.userYue}}</span>
							<span v-else style="color:#FF182A">-{{scope.row.consume.userYue}}</span>
						</template>
					</el-table-column>
					<el-table-column prop="address" label="变动后余额">
						<template slot-scope="scope">
							{{scope.row.after.userYue}}
						</template>
					</el-table-column>
					<el-table-column prop="address" label="备注">
						<template slot-scope="scope">
							{{scope.row.remark}}
						</template>
					</el-table-column>
				</el-table>
			</div>
			<div style="width:100%;position: absolute;bottom: 0;right: 0;">
				<page :pageSizes="pageSizes"	@size-change="handleSizeChange" :pageIndex="page.pageIndex" :pageSize="page.pageSize" :total="page.total" @change="changePage"></page>
			</div>
		</div>
		<div v-if="tabIndex===1" class="tabContent">
			<!-- 搜索栏 -->
			<div class="tabView">
				<div class="tabViewB">
					<div class="search">
						<div class="searchTitle">类型</div>
						<el-select v-model="consumeType1" @change="changeConsumeType1" placeholder="请选择" style="width: 143px;">
							<el-option v-for="(item,index) in [
								{label:'全部',value:0},
								{label:'充值',value:1},
								{label:'修改',value:5},
							]" :key="index" :label="item.label" :value="item.value">
							</el-option>
						</el-select>
					</div>
					<div class="search">
						<div class="searchTitle">日期</div>
						<el-date-picker
							v-model="timeRange1"
							@change="changeTimeRange1"
							type="daterange"
							start-placeholder="开始时间"
							end-placeholder="结束时间"
						>
						</el-date-picker>
					</div>
					<div class="tabViewBR">
						<!-- <el-button>导出</el-button>
						<el-button type="primary" >新建商品</el-button> -->
					</div>
				</div>
			</div>
			<!-- 表格 -->
			<div class="tableBox">
				<el-table :data="jifenRecordList" style="width: 100%" height="100%">
					<el-table-column prop="date" label="日期">
						<template slot-scope="scope">
							{{scope.row.operationInfo.create.handleTime}}
						</template>
					</el-table-column>
					<el-table-column prop="name" label="关联单号">
						<template slot-scope="scope">
							<span class="linkColor" @click="toOrderDetail(scope.row)">{{scope.row.orderNum}}</span>
						</template>
					</el-table-column>
					<el-table-column prop="province" label="类型">
						<template slot-scope="scope">
							<span v-if="scope.row.consumeType===1">充值</span>
							<span v-if="scope.row.consumeType===2">消费</span>
							<span v-if="scope.row.consumeType===3">退货</span>
							<span v-if="scope.row.consumeType===4">换货</span>
							<span v-if="scope.row.consumeType===5">修改</span>
							<span v-if="scope.row.consumeType===6">退款</span>
						</template>
					</el-table-column>
					<el-table-column prop="address" label="变化值">
						<template slot-scope="scope">
							<span v-if="scope.row.consumeType===1||scope.row.consumeType===3||scope.row.consumeType===5" style="color:rgb(24, 164, 76)">+{{scope.row.currScore}}</span>
							<span v-else style="color:#FF182A">-{{scope.row.currScore}}</span>
						</template>
					</el-table-column>
					<el-table-column prop="address" label="变动后成长值">
						<template slot-scope="scope">
							{{scope.row.afterScore}}
						</template>
					</el-table-column>
					<el-table-column prop="address" label="备注">
						<template slot-scope="scope">
							{{scope.row.remark}}
						</template>
					</el-table-column>
				</el-table>
			</div>
			<div style="width:100%;position: absolute;bottom: 0;right: 0;">
				<page :pageSizes="pageSizes1"	@size-change="handleSizeChange1" :pageIndex="page1.pageIndex" :pageSize="page1.pageSize" :total="page1.total" @change="changePage1"></page>
			</div>
		</div>
		<div v-if="dialogEditYue_state">
			<dialogEditYue :userInfo="userDetail" @submit="submit_dialogEditYue"></dialogEditYue>
		</div>
		<div v-if="dialogEditGrowUp_state">
			<dialogEditGrowUp :userInfo="userDetail" @submit="submit_dialogEditGrowUp"></dialogEditGrowUp>
		</div>
		<div v-if="dialogRechargeSee_state">
			<dialogRechargeSee :no="curItem.orderNum" @submit="dialogRechargeSee_state=false"></dialogRechargeSee>
		</div>
		<div v-if="dialogOrderSee_state">
			<dialogOrderSee :no="curItem.orderNum" @submit="dialogOrderSee_state=false"></dialogOrderSee>
		</div>
		<div v-if="dialogReturnOrderSee_state">
			<dialogReturnOrderSee :no="curItem.orderNum" @submit="dialogReturnOrderSee_state=false"></dialogReturnOrderSee>
		</div>
	</div>
</template>
<script>
	import dialogEditYue from "./dialogEditYue.vue"
	import dialogEditGrowUp from "./dialogEditGrowUp.vue"
	import dialogRechargeSee from "../../mendian/shouyin/dialog/dialogRechargeSee.vue"
	import dialogOrderSee from "../../mendian/shouyin/dialog/dialogOrderSee.vue"
	import dialogReturnOrderSee from "../../mendian/shouyin/dialog/dialogReturnOrderSee.vue"
	import page from "../../../components/page.vue"
	export default {
		components: {dialogEditYue,dialogEditGrowUp,page,dialogRechargeSee,dialogOrderSee,dialogReturnOrderSee},
		data() {
			return {
				userDetail:{
					id:'',
					basicInfo:{},
					cardInfo:{},
					companyInfo:{},
					status:{},
					vipGrades:{},
					pets_info:[],
				},
				// tab切换
				tabIndex:0,
				// 余额
				consumeType:0,
				timeRange:'',
				page:{
					pageIndex:1,
					pageSize:10,
					total:0,
				},
				pageSizes: [10, 15,30,60],
				yueRecordList:[],
				// 积分
				consumeType1:0,
				timeRange1:'',
				page1:{
					pageIndex:1,
					pageSize:10,
					total:0,
				},
				pageSizes1: [10, 15,30,60],
				jifenRecordList:[],
				// 修改余额
				dialogEditYue_state:false,
				// 修改成长值
				dialogEditGrowUp_state:false,
				// 充值订单详情
				curItem:{},
				dialogRechargeSee_state:false,
				// 消费订单详情
				dialogOrderSee_state:false,
				// 换货订单详情
				dialogReturnOrderSee_state:false,
			}
		},
		computed:{
			
		},
		mounted() {
			this.getDetail()
			this.getYueRecordList()
			this.getGrowUpRecordList()
		},
		methods: {
			
			// 获取用户详情
			getDetail(){
				let params = {
					id:this.$route.query.id
				}
				let loading = this.$loading()
				this.$http.get("/customer/Member/Get",params).then(res=>{
					loading.close()
					if(res.code===0){
						this.userDetail = res.data
					}
				})
			},
			// tab切换
			changeTabIndex(){
				console.log(this.tabIndex)
			},
			// 余额记录
			changeConsumeType(){
				this.page.pageIndex=1;
				this.getYueRecordList();
			},
			changeTimeRange(){
				this.page.pageIndex=1;
				this.getYueRecordList();
			},
			changePage(num){
				this.page.pageIndex = num;
				this.getYueRecordList()
			},
			handleSizeChange(val) {
			this.page.pageIndex = 1;
			this.page.pageSize = val
			this.getYueRecordList()
		},
			getYueRecordList(){
				let startTime='';
				let endTime='';
				if(this.timeRange&&this.timeRange.length==2){
					startTime = this.$public.FTimeStr10(this.timeRange[0])
					endTime = this.$public.FTimeStr10(this.timeRange[1])
				}
				let params = {
					memberId:this.$route.query.id,
					"consumeType": this.consumeType, // 0:全部、1:充值、2:消费、3:退货、4:换货、5:修改 
					"type": 1,  // -1.全部1.余额 2.积分
					"startTime": startTime,
					"endTime": endTime,
					"keyword": "",
					"pageIndex": this.page.pageIndex,
					"pageSize": this.page.pageSize,
				}
				let loading = this.$loading()
				this.$http.post("/customer/Member/PropertyRecordList",params).then(res=>{
					loading.close()
					if(res.code===0){
						this.yueRecordList = res.data.list
						this.page.total = res.data.count
					}
				})
			},
			// 积分记录
			changeConsumeType1(){
				this.page1.pageIndex=1;
				this.getGrowUpRecordList();
			},
			changeTimeRange1(){
				this.page1.pageIndex=1;
				this.getGrowUpRecordList();
			},
			changePage1(num){
				this.page1.pageIndex = num;
				this.getGrowUpRecordList()
			},
			handleSizeChange1(val) {
			this.page.pageIndex1 = 1;
			this.page.pageSize1 = val
			this.getGrowUpRecordList()
		},
			getGrowUpRecordList(){
				let startTime='';
				let endTime='';
				if(this.timeRange1&&this.timeRange1.length==2){
					startTime = this.$public.FTimeStr10(this.timeRange1[0])
					endTime = this.$public.FTimeStr10(this.timeRange1[1])
				}
				let params = {
					memberId:this.$route.query.id,
					"consumeType": this.consumeType1,  // 0:全部、1:充值、2:消费、3:退货、4:换货、5:修改
					"type": -1,  // -1.全部1.余额 2.积分
					"startTime": startTime,
					"endTime": endTime,
					"keyword": "",
					"pageIndex": this.page1.pageIndex,
					"pageSize": this.page1.pageSize,
				}
				let loading = this.$loading()
				this.$http.post("/customer/Member/GrowthScoreRecordList",params).then(res=>{
					loading.close()
					if(res.code===0){
						this.jifenRecordList = res.data.list
						this.page1.total = res.data.count
					}
				})
			},
			// 修改余额
			submit_dialogEditYue(obj){
				this.dialogEditYue_state=false
				if(obj.type==='success'){
					this.getDetail()
					this.getYueRecordList()
				}
			},
			// 修改成长值
			submit_dialogEditGrowUp(obj){
				this.dialogEditGrowUp_state=false
				if(obj.type==='success'){
					this.getDetail();
					this.getGrowUpRecordList()
				}
			},
			// 跳转订单详情
			toOrderDetail(row){
				this.curItem = row;
				// 1:充值、2:消费、3:退货、4:换货、5:修改
				if(row.consumeType===1){
					this.dialogRechargeSee_state = true;
				}else if(row.consumeType===2){
					this.dialogOrderSee_state = true;
				}else if(row.consumeType===3||row.consumeType===4){
					this.dialogReturnOrderSee_state = true;
				}
			},
		}
	}
</script>
<style lang="scss" scoped>
	.page {
		width:100%;height: 100%;border-radius: 20px;overflow: hidden;color: $fontColor;background-color: #fff;
		.title{
			display: flex;justify-content: space-between;border-bottom: 1px solid #f2f2f2;height: 68px;box-sizing: border-box;padding:14px 20px;
			.titleL{
				font-size: 16px;color:$fontColor;line-height: 40px;
			}
			.titleR{
				
			}
		}
		.tit{
			padding:32px 20px 8px;display: flex;justify-content: space-between;
			.titL{
				font-size: 16px;color:$fontColor;background: url(../../../assets/img/cangku/detailName.png) 2px 8px no-repeat;background-size: 24px 24px;
				.titText{
					padding-left:12px;display:inline-block;line-height: 40px;
				}
				.editImg{
					width:20px;height:20px;margin-left:8px;vertical-align: middle;cursor: pointer;
				}
			}
		}
		.msgBox{
			margin:0 20px;background-color: #F7F7F7;padding:10px 30px;box-sizing: border-box;border-radius: 20px;
			.msgItem{
				font-size: 14px;color:$fontColor;line-height: 40px;
				.editImg{
					width:20px;height:20px;margin-left:8px;vertical-align: middle;cursor: pointer;
				}
			}
		}
		.tabContent{
			width:100%;height: calc(100% - 267px);padding-bottom:52px;box-sizing: border-box;position: relative;
			// 搜索信息
			.tabView {
				height: 80px;
				border-radius: 20px;
				background: #fff;
				display: flex;
				flex-direction: column;
			
				.tabViewT {
					height: 80px;
					border-bottom: solid 1px #F2F2F2;
					display: flex;
					align-items: center;
			
					.tabItem {
						width: 116px;
						line-height: 40px;
						text-align: center;
						font-size: 16px;
						border-radius: 40px;
						margin-left: 20px;
						cursor: pointer;
						background: #FFF0E0;
					}
			
					.active {
						background: #F77E04;
						color: #fff;
					}
				}
			
				.tabViewB {
					
					flex: 1;
					display: flex;
					align-items: center;
					padding: 0 20px;
			
					.search {
						display: flex;
						align-items: center;
						margin-right: 32px;
			
						.searchTitle {
							font-size: 14px;
							padding-right: 4px;
							color: $fontColor;
						}
					}
			
					.tabViewBR {
						flex: 1;
						display: flex;
						align-items: center;
						justify-content: flex-end;
					}
				}
			}
			.tableBox{
				height: calc(100% - 80px);
			}
		}
	}
</style>
