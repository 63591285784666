<template>
	<div class="HSMask">
		<div class="HSDialog">
			<div class="HSDialogTitleLine" style="border:0;">
				<div class="HSDialogTitle"></div>
				<img class="HSDialogCloseImg" src="../../../../assets/img/HS/close.png" @click="close"/>
			</div>
			<!-- //////////////////////////////////////////////////////////////////////////////// -->
			<div class="content">
				<div class="contentL">
					<div class="contentTit">退货订单信息</div>
					<div class="contentItem">
						<div class="label">订单号：</div>
						<div class="value">{{orderDetail.refundNo}}</div>
					</div>
					<div class="contentItem">
						<div class="label">原订单号：</div>
						<div class="value">{{orderDetail.orderNo}}</div>
					</div>
					<div class="contentItem">
						<div class="label">顾客：</div>
						<div class="value">
							{{orderDetail.userInfo.userName}} ( {{orderDetail.userInfo.userMobile}} )
						</div>
					</div>
					<div class="contentItem">
						<div class="label">创建时间：</div>
						<div class="value">
							{{$public.FTime(orderDetail.operationInfo.create.handleTimeStamp,"YYYY-MM-DD")}}
						</div>
					</div>
					<div class="contentItem">
						<div class="label">应付金额：</div>
						<div class="value">
							{{orderDetail.orderPrice}}
						</div>
					</div>
					<div class="contentItem">
						<div class="label">实收金额：</div>
						<div class="value">
							{{orderDetail.totalPrice}}
						</div>
					</div>
					<div class="contentItem">
						<div class="label">订单备注：</div>
						<div class="value">
							{{orderDetail.remark}}
						</div>
					</div>
					<div class="contentItem">
						<div class="label">结算方式：</div>
						<div class="value">
							<span v-if="!$public.isNull(orderDetail.payInfo)">
								<span v-for="(item,index) in orderDetail.payInfo.payList" :key="index">
									<span >
										{{$public.getMatchingItem(paymentList,'value',item.payType).label}}
										{{item.money}}
									</span>
								</span>
							</span>
						</div>
					</div>
					<div class="contentItem">
						<div class="label">结算备注：</div>
						<div class="value">
							<span v-if="!$public.isNull(orderDetail.payInfo)">
								{{orderDetail.payInfo.payRemark}}
							</span>
						</div>
					</div>
				</div>
				<div class="contentR">
					<div class="contentTit">退货商品</div>
					<el-table :data="orderDetail.refundItemList?orderDetail.refundItemList:[]" height="600px">
						<el-table-column label="消费项目">
							<template slot-scope="scope">
								{{scope.row.productName}}
							</template>
						</el-table-column>
						<el-table-column label="宠物">
							<template slot-scope="scope">
								{{scope.row.petInfo.petName}}
							</template>
						</el-table-column>
						<el-table-column label="规格">
							<template slot-scope="scope">
								{{scope.row.modelName}}
							</template>
						</el-table-column>
						<el-table-column label="零售价">
							<template slot-scope="scope">
								{{scope.row.originalPrice}}
							</template>
						</el-table-column>
						<el-table-column label="活动">
							<template slot-scope="scope">
								{{scope.row.orderItemActivity.activityName}}
							</template>
						</el-table-column>
						<el-table-column label="优惠价">
							<template slot-scope="scope">
								{{scope.row.price}}
							</template>
						</el-table-column>
						<el-table-column width="50" label="数量">
							<template slot-scope="scope">
								{{scope.row.num}}
							</template>
						</el-table-column>
						<el-table-column label="总金额">
							<template slot-scope="scope">
								{{$public.cheng(scope.row.num,scope.row.price)}}
							</template>
						</el-table-column>
						<el-table-column label="销售人员">
							<template slot-scope="scope">
								<span v-for="(item,index) in scope.row.salesList" :key="index"> {{item.name}} </span>
							</template>
						</el-table-column>
					</el-table>
				</div>
			</div>
			<!-- //////////////////////////////////////////////////////////////////////////////// -->
			<div style="height: 80px;"></div>
			<!-- <div class="HSDialogButtonLine" style="border-top:0">
				<div class="HSDialogButtonCancel" @click="close">取消</div>
				<div class="HSDialogButtonConfirm" @click="submit">确认</div>
			</div> -->
		</div>
	</div>
</template>
<script>
	export default {
		data() {
			return {
				orderDetail:{
					userInfo:{},
					payInfo:{},
					operationInfo:{create:{}},
					orderItemList:[],
				},
			}
		},
		computed:{
			stateList(){
				let list = this.$public.getOrderStateList()
				list.unshift({label:"全部",value:-1})
				return list
			},
			paymentList(){
				let list = this.$public.getPaymentList()
				list.unshift({label:"全部",value:-1})
				return list
			},
		},
		props:{
			no:{
				default:''
			}
		},
		mounted(){
			this.getDetail()
		},
		methods:{
			// 关闭
			close(){
				this.$emit("submit",{type:"close",data:""})
			},
			// 提交
			submit(){
				this.$emit("submit",{type:"success",data:""})
			},
			getDetail(){
				let params = {
					orderNo:this.no
				}
				let loading = this.$loading()
				this.$http.get("/order/OrderRefund/GetInfo",params).then(res=>{
					loading.close()
					if(res.code===0){
						this.orderDetail = res.data
					}
				})
			},
		}
	};
</script>
<style lang="scss" scoped>
	.content{
		width:1200px;height: 600px;display: flex;justify-content: space-around;padding:0 15px;box-sizing: border-box;
		.contentL{
			width:318px;border-radius: 20px;border:1px solid #D8D8D8;overflow: hidden;
			.contentTit{
				line-height: 46px;text-align: center;background-color: #F8F9F9;font-size: 16px;color:$fontColor;font-weight: 700;border-bottom: 1px solid #D8D8D8;
			}
			.contentItem{
				margin:0 8px;line-height: 44px;border-bottom:1px solid #F2F2F2;display: flex;
				.label{
					font-size: 14px;color:#666;width:80px;text-align: right;
				}
				.value{
					font-size: 14px;color:$fontColor;
				}
			}
		}
		.contentR{
			width:808px;border-radius: 20px;border:1px solid #D8D8D8;overflow: hidden;
			.contentTit{
				line-height: 46px;text-align: center;background-color: #F8F9F9;font-size: 16px;color:$fontColor;font-weight: 700;border-bottom: 1px solid #D8D8D8;
			}
		}
	}
</style>
